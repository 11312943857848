import { defineStore } from 'pinia'

export const useIframeStore = defineStore('iframe', () => {
  const height = ref(0)

  function observeElement(ref: Ref<HTMLDivElement | undefined>) {
    if (!ref.value) return

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === ref.value) {
          height.value = entry.borderBoxSize[0].blockSize
          window.parent.postMessage(height.value, import.meta.env.VITE_SH_URL)
        }
      }
    })

    observer.observe(ref.value)

    return observer
  }

  return {
    observeElement,
  }
})
