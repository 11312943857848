<script setup lang="ts">
import { useIframeStore } from '@/store/iframe'

const { isShown } = useToast()

const container: Ref<HTMLDivElement | undefined> = ref()
const iframeStore = useIframeStore()
let observer: ResizeObserver | undefined

onMounted(() => {
  observer = iframeStore.observeElement(container)
})

onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<template>
  <section ref="container" class="flex flex-col bg-gray-100 px-5 py-8">
    <main class="w-full">
      <AppToast v-if="isShown" />
      <RouterView />
    </main>
  </section>
</template>
